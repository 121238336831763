// we need these to be able to change the row color

import { SITE_SLUG } from './general'

// otherwise we would need to pass the class from the serve and hard code the class
export const PAYMENT_STATUSES = {
  CREATED: 'CREATED',
  IN_PAYING_PROCESS: 'IN_PAYING_PROCESS',
  ERROR: 'ERROR',
  COMPLETED: 'COMPLETED',
}

export enum REVENUE_SHARE_TYPE {
  CONTENTID = 'CONTENTID',
  PROGRESSIVE_CONTENTID = 'PROGRESSIVE_CONTENTID',
  DISTRIBUTION = 'DISTRIBUTION',
  PROGRESSIVE_DISTRIBUTION = 'PROGRESSIVE_DISTRIBUTION',
  ARTTRACK = 'ARTTRACK',
  CHANNEL = 'CHANNEL',
  PROGRESSIVE_CHANNEL = 'PROGRESSIVE_CHANNEL',
  CLAIMSPLIT = 'CLAIMSPLIT',
}

export enum REVENUE_SPLIT_TYPE {
  ASSET = 'Asset',
  CHANNEL = 'Channel',
  CLAIM = 'Claim',
  CHANNEL_VIDEO = 'ChannelVideo',
}

export const FIRST_DATE_WITH_EARNINGS_DATA = '2023-01-01'

export const DEFAULT_NR_OF_PAYMENT_MONTHS_TO_SHOW = 6

export const SITES_WITH_REPORT = [
  SITE_SLUG.ELITE_ALLIANCE,
  SITE_SLUG.EPIC_ELITE,
  SITE_SLUG.ELITE_ALLIANCE_MUSIC,
  SITE_SLUG.SONGPITCH,
  SITE_SLUG.SONGMATE,
]
