import clientWrapper from '../../clientWrapper'
import { IGenericResponse } from '../../api.types'
import { SERVICE_NAME } from '../../../constants'
import { IBulkClaimResponse } from './bulkUpdateClaimIssuesStatus'

export type IRequestLicenseByEmailProps = {
  claimId: string
  email: string
  subject: string
  emailTemplateUuid: string
  body: string
}

export type IRequestLicenseByEmailResponse = IGenericResponse<'requestLicenseByEmail', IBulkClaimResponse>

export async function requestLicenseByEmail(
  input: IRequestLicenseByEmailProps
): Promise<IRequestLicenseByEmailResponse> {
  const query = `
    mutation requestLicenseByEmail($input: RequestLicenseFilterInput!) {
      requestLicenseByEmail(input: $input) {
        claim {
          claimDate
          users {
            userUuid
          }
        }
        claimId
        error
        isUpdated
      }
    }
  `
  return clientWrapper(SERVICE_NAME.ASSETS, query, { input }) as Promise<IRequestLicenseByEmailResponse>
}
