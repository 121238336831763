import clientWrapper from '../../clientWrapper'

import { IGenericResponse } from '../../api.types'

import { IClaim } from '../../../models/assets/Claim.model'
import { IPaginationParams } from '../../../types/common'

import { CLAIM_TAKE_ACTION_ROLE, SERVICE_NAME } from '../../../constants'
import { claimsFragment } from './fragments'

export type IGetClaimsResponse = IGenericResponse<'claims', { claims: IClaim[]; total: number }>

export type IGetClaimsProps = {
  pagination?: IPaginationParams
  filters?: {
    assetId?: string
    search?: string
    userUuids?: string[]
    siteUuid?: string
    cmsUuid?: string
    status?: string
    isSplit?: boolean
    from?: string
    to?: string
    historyEventType?: string
    appealExplanation?: string
    disputeNote?: string
    disputeReason?: string
    isPrivateVideo?: boolean
    isTakeActionExpired?: boolean
    takeActionRoles?: CLAIM_TAKE_ACTION_ROLE[]
    takeActionBy?: string
    channelIds?: string[]
  }
}

export async function getClaims(
  { pagination, filters }: IGetClaimsProps,
  fragment: string = claimsFragment
): Promise<IGetClaimsResponse> {
  const query = `
    query claims ($pagination: PaginationInput, $filters: ClaimsFilterInput) {
      claims (pagination: $pagination, filters: $filters) {
        total
        ${fragment}
      }
    }
  `

  return (await clientWrapper(SERVICE_NAME.ASSETS, query, { pagination, filters })) as IGetClaimsResponse
}
