import { cast, flow, Instance, types } from 'mobx-state-tree'
import moment from 'moment'
import { toast } from 'react-toastify'

import {
  ASSET_ID_REGEX,
  CLAIM_STATUSES,
  CLAIM_TAKE_ACTION_ROLE,
  SPLIT_STATUSES,
  YOUTUBE_VIDEO_URL_OR_ID_REGEX,
} from '../../constants'
import { youtubeUrlParser } from '../../helpers/utils'

import { getClaims, IGetClaimsProps, IGetClaimsResponse } from '../../api/assets-api/contentId/getClaims'
import { IReleaseClaimResponse, releaseClaim } from '../../api/assets-api/contentId/releaseClaim'

import { Pagination } from '../Pagination.model'

import {
  bulkUpdateClaimIssuesStatus,
  IBulkClaimResponse,
  IBulkUpdateClaimIssueStatusResponse,
} from '../../api/assets-api/contentId/bulkUpdateClaimIssuesStatus'
import { getClaimsIssues, IGetClaimsIssuesResponse } from '../../api/assets-api/contentId/getClaimsIssues'
import {
  IRequestLicensesByEmailResponse,
  requestLicensesByEmail,
} from '../../api/assets-api/contentId/requestLicensesByEmail'
import {
  IRequestToTakeActionProps,
  IRequestToTakeActionResponse,
  requestToTakeAction,
} from '../../api/assets-api/contentId/requestToTakeAction'
import downloadClaimsCSV from '../../api/assets-api/other/downloadDisputedClaimsCSV'
import { Claim, IClaim } from './Claim.model'
import {
  IRequestLicenseByEmailProps,
  IRequestLicenseByEmailResponse,
  requestLicenseByEmail,
} from '../../api/assets-api/contentId/requestLicenseByEmail'

export const ContentIdClaimList = types
  .model({
    list: types.array(Claim),
  })
  .volatile(() => ({
    loading: false,
    loadingDownload: false,
    pagination: Pagination.create({ totalItems: 0 }),
    textFilter: '',
    monthFilter: '',
    userUuidsFilter: [] as string[],
    siteUuidFilter: '',
    assetUuidFilter: '',
    cmsUuidFilter: '',
    statusFilter: '',
    splitStatusFilter: '',
    historyEventTypeFilter: '',
    appealExplanationFilter: '',
    disputeNoteFilter: '',
    disputeReasonFilter: '',
    channelIdsFilter: [] as string[],
    totalUnique: null as null | number,
    isPrivateVideoFilter: null as null | boolean,
    isTakeActionExpiredFilter: null as null | boolean,
    takeActionRolesFilter: [] as CLAIM_TAKE_ACTION_ROLE[],
    takeActionByFilter: '',
  }))
  .actions(self => ({
    resetFilters() {
      self.textFilter = ''
      self.monthFilter = ''
      self.userUuidsFilter = [] as string[]
      self.siteUuidFilter = ''
      self.cmsUuidFilter = ''
      self.statusFilter = ''
      self.splitStatusFilter = ''
      self.historyEventTypeFilter = ''
      self.appealExplanationFilter = ''
      self.disputeNoteFilter = ''
      self.disputeReasonFilter = ''
      self.channelIdsFilter = [] as string[]
      self.totalUnique = null
      self.isPrivateVideoFilter = null
      self.isTakeActionExpiredFilter = null
      self.takeActionRolesFilter = [] as CLAIM_TAKE_ACTION_ROLE[]
      self.takeActionByFilter = ''
    },
    setTextFilter(text: string) {
      const youtubeUrl = youtubeUrlParser(text)
      self.textFilter = youtubeUrl || text
    },
    setUserUuidsFilter(userUuids: string[]) {
      self.userUuidsFilter = userUuids
    },
    setSiteUuidFilter(site: string) {
      self.siteUuidFilter = site
    },
    setCmsUuidFilter(cms: string) {
      self.cmsUuidFilter = cms
    },
    setMonthFilter(month: string) {
      self.monthFilter = month
    },
    setStatusFilter(status: string) {
      self.statusFilter = status
    },
    setSplitFilter(split: string) {
      self.splitStatusFilter = split
    },
    setAssetUuidFilter(assetUuid: string) {
      self.assetUuidFilter = assetUuid
    },
    setHistoryEventTypeFilter(type: string) {
      self.historyEventTypeFilter = type
    },
    setAppealExplanationFilter(explanation: string) {
      self.appealExplanationFilter = explanation
    },
    setDisputeNoteFilter(note: string) {
      self.disputeNoteFilter = note
    },
    setDisputeReasonFilter(reason: string) {
      self.disputeReasonFilter = reason
    },
    setChannelIdsFilter(channelIds: string[]) {
      self.channelIdsFilter = channelIds
    },
    setIsPrivateVideoFilter(state: boolean | null) {
      self.isPrivateVideoFilter = state
    },
    setIsTakeActionExpiredFilter(state: boolean | null) {
      self.isTakeActionExpiredFilter = state
    },
    setTakeActionRolesFilter(roles: CLAIM_TAKE_ACTION_ROLE[]) {
      self.takeActionRolesFilter = roles
    },
    setTakeActionByFilter(uuid: string) {
      self.takeActionByFilter = uuid
    },
  }))
  .actions(self => ({
    getTextSearchParam() {
      if (ASSET_ID_REGEX.test(self.textFilter)) return 'assetId'
      if (YOUTUBE_VIDEO_URL_OR_ID_REGEX.test(self.textFilter)) return 'videoId'
      return 'search'
    },
    updateListWithClaim(updatedClaim: IClaim) {
      const updatedList = self.list.map(claim => {
        return claim.claimId !== updatedClaim.claimId ? claim : updatedClaim
      })

      self.list = cast(updatedList)
    },
  }))
  .actions(self => ({
    getQueryVariables() {
      const variables: IGetClaimsProps = {
        pagination: self.pagination.allQueryParams,
        filters: {
          ...(self.textFilter && { [self.getTextSearchParam()]: self.textFilter }),
          ...(self.userUuidsFilter.length > 0 && { userUuids: self.userUuidsFilter }),
          ...(self.siteUuidFilter && { siteUuid: self.siteUuidFilter }),
          ...(self.assetUuidFilter && { assetId: self.assetUuidFilter }),
          ...(self.cmsUuidFilter && { cmsUuid: self.cmsUuidFilter }),
          ...(self.statusFilter && { status: self.statusFilter.toLowerCase() }),
          ...(self.historyEventTypeFilter && { historyEventType: self.historyEventTypeFilter }),
          ...(self.appealExplanationFilter && { appealExplanation: self.appealExplanationFilter }),
          ...(self.disputeNoteFilter && { disputeNote: self.disputeNoteFilter }),
          ...(self.disputeReasonFilter && { disputeReason: self.disputeReasonFilter }),
          ...(self.channelIdsFilter.length > 0 && { channelIds: self.channelIdsFilter }),
          ...(self.splitStatusFilter && { isSplit: self.splitStatusFilter === SPLIT_STATUSES.ASSIGNED.value }),
          ...(self.isPrivateVideoFilter !== null && { isPrivateVideo: Boolean(self.isPrivateVideoFilter) }),
          ...(self.isTakeActionExpiredFilter !== null && { isTakeActionExpired: self.isTakeActionExpiredFilter }),
          ...(self.takeActionRolesFilter.length > 0 && { takeActionRoles: self.takeActionRolesFilter }),
          ...(self.takeActionByFilter && { takeActionBy: self.takeActionByFilter }),
          ...(self.monthFilter && {
            from: moment(self.monthFilter).startOf('month').format('YYYY-MM-DD'),
            to: moment(self.monthFilter).endOf('month').format('YYYY-MM-DD'),
          }),
        },
      }

      return variables
    },
    processBulkResponse(entries: IBulkClaimResponse[], sendToast = true) {
      // Successful updates
      const updatedClaims = entries.filter(entry => entry.isUpdated)
      if (updatedClaims.length > 0) {
        updatedClaims.forEach(entry => entry.claim && self.updateListWithClaim(entry.claim))

        const updatedClaimsIds = updatedClaims.map(entry => entry.claimId)
        const successMessage = `Successfully updated claims with ids: ${updatedClaimsIds.join(', ')}`

        if (sendToast) {
          toast.success(successMessage)
        }
      }

      // Failed updates
      const erroredClaims = entries.filter(entry => !entry.isUpdated && entry.error)
      if (erroredClaims.length > 0) {
        const errorMessage = `Failed to update the following claims: ${erroredClaims
          .map(entry => `${entry.claimId} - ${entry.error}`)
          .join('; ')}`

        if (sendToast) {
          toast.error(errorMessage)
        }
      }
    },
  }))
  .actions(self => ({
    load: flow(function* (fragment?: string) {
      try {
        self.loading = true

        const resp: IGetClaimsResponse = yield getClaims(self.getQueryVariables(), fragment)
        if (resp && resp?.data.data?.claims) {
          self.list = cast(resp.data.data.claims.claims)
          self.pagination.setTotalItems(resp.data.data.claims.total)
        }

        self.loading = false
      } catch (e) {
        console.error(e)
        self.loading = false
      }
    }),
    loadIssues: flow(function* (fragment?: string) {
      try {
        self.loading = true

        const resp: IGetClaimsIssuesResponse = yield getClaimsIssues(self.getQueryVariables(), fragment)
        if (resp && resp?.data.data?.claimsIssues) {
          self.list = cast(resp.data.data.claimsIssues.claims)
          self.pagination.setTotalItems(resp.data.data.claimsIssues.total)
          self.totalUnique = Number.isNaN(resp.data.data.claimsIssues.totalUnique)
            ? null
            : resp.data.data.claimsIssues.totalUnique || 0
        }

        self.loading = false
      } catch (e) {
        console.error(e)
        self.loading = false
      }
    }),
  }))
  .views(self => ({
    getByUuid(uuid: string) {
      return self.list.find(item => item.uuid === uuid)
    },
    getByUuids(uuids: string[]) {
      return self.list.filter(item => uuids.includes(item.uuid || ''))
    },
  }))
  .actions(self => ({
    release: flow(function* (claimId: string) {
      let updatedClaim = self.list.find(claim => claim.claimId === claimId)

      try {
        self.loading = true

        const resp: IReleaseClaimResponse = yield releaseClaim({ claimId })

        if (resp && resp.data.data?.releaseClaim) {
          self.updateListWithClaim(resp.data.data.releaseClaim)

          updatedClaim = resp.data.data.releaseClaim
        }

        self.loading = false
      } catch (e) {
        console.error(e)
        self.loading = false
      }

      return updatedClaim
    }),
    requestToTakeAction: flow(function* (props: IRequestToTakeActionProps) {
      if (props.claimIds.length === 0) {
        return
      }

      try {
        self.loading = true

        const resp: IRequestToTakeActionResponse = yield requestToTakeAction(props)
        if (resp && resp.data.data?.requestToTakeAction) {
          self.processBulkResponse(resp.data.data.requestToTakeAction)
        }

        self.loading = false
      } catch (err) {
        console.error(err)
        self.loading = false
      }
    }),
    bulkUpdateIssueStatus: flow(function* (claimIds: string[], newStatus: CLAIM_STATUSES) {
      if (claimIds.length === 0) {
        return
      }

      if (newStatus === CLAIM_STATUSES.TAKEDOWN && claimIds.length === 1) {
        return
      }

      try {
        self.loading = true

        const resp: IBulkUpdateClaimIssueStatusResponse = yield bulkUpdateClaimIssuesStatus({ newStatus, claimIds })
        if (resp && resp.data.data?.bulkUpdateClaimIssuesStatus) {
          self.processBulkResponse(resp.data.data.bulkUpdateClaimIssuesStatus)
        }

        self.loading = false
      } catch (err) {
        console.error(err)
        self.loading = false
      }
    }),
    requestLicensesByEmail: flow(function* (
      claimIds: string[],
      email: string,
      userUuid: string,
      emailTemplateUuid?: string
    ) {
      if (claimIds.length === 0) {
        return
      }

      try {
        self.loading = true

        const resp: IRequestLicensesByEmailResponse = yield requestLicensesByEmail({
          email,
          claimIds,
          userUuid,
          emailTemplateUuid,
        })
        if (resp && resp.data.data?.requestLicensesByEmail) {
          self.processBulkResponse(resp.data.data.requestLicensesByEmail, true)

          self.loading = false
        }
      } catch (err) {
        console.error(err)
      }

      self.loading = false
    }),
    requestLicenseByEmail: flow(function* (input: IRequestLicenseByEmailProps) {
      if (!input.claimId) return

      try {
        self.loading = true

        const resp: IRequestLicenseByEmailResponse = yield requestLicenseByEmail(input)
        if (resp && resp.data.data?.requestLicenseByEmail) {
          self.processBulkResponse([resp.data.data.requestLicenseByEmail], true)

          self.loading = false
        }
      } catch (err) {
        console.error(err)
      } finally {
        self.loading = false
      }
    }),
    downloadCSV: flow(function* ({ title }: { title?: string }) {
      try {
        self.loadingDownload = true

        yield downloadClaimsCSV(
          {
            ...(self.monthFilter && { startDate: moment(self.monthFilter).format('YYYY-MM-DD') }),
            ...(self.monthFilter && { endDate: moment(self.monthFilter).add(1, 'months').format('YYYY-MM-DD') }),
            ...(self.textFilter && { search: self.textFilter }),
            ...(self.statusFilter && { status: self.statusFilter }),
            ...(self.userUuidsFilter && self.userUuidsFilter.length > 0 && { userUuids: self.userUuidsFilter }),
            ...(self.siteUuidFilter && { siteUuid: self.siteUuidFilter }),
            ...(self.cmsUuidFilter && { cmsUuid: self.cmsUuidFilter }),
            ...(self.disputeReasonFilter && { disputeReason: self.disputeReasonFilter }),
            ...(self.isPrivateVideoFilter !== null && { isPrivateVideo: Boolean(self.isPrivateVideoFilter) }),
            ...(self.historyEventTypeFilter && { historyEventType: self.historyEventTypeFilter }),
            ...(self.isTakeActionExpiredFilter && { isTakeActionExpired: Boolean(self.isTakeActionExpiredFilter) }),
            ...(self.takeActionRolesFilter &&
              self.takeActionRolesFilter.length > 0 && { takeActionRoles: self.takeActionRolesFilter }),
            ...(self.disputeNoteFilter && { disputeNote: self.disputeNoteFilter }),
          },
          title
        )
      } catch (err) {
        console.error(err)
      } finally {
        self.loadingDownload = false
      }
    }),
  }))

export type IContentIdClaimList = Instance<typeof ContentIdClaimList>
