import moment from 'moment'
import { AUDIO_WAV_MIME_TYPE, CSV_MIME_TYPE, IMG_JPEG_MIME_TYPE } from './upload'

export enum PLATFORM_STATES {
  PENDING = 'PENDING',
  QUEUED_FOR_DELIVERY = 'QUEUED_FOR_DELIVERY',
  DELIVERY_FINISHED = 'DELIVERY_FINISHED',
  DELIVERY_STARTED = 'DELIVERY_STARTED',
  DELIVERY_ERROR = 'DELIVERY_ERROR',
  LIVE = 'LIVE',
  UPCOMING = 'UPCOMING',
  TAKEN_DOWN = 'TAKEN_DOWN',
  UNKNOWN = 'UNKNOWN',
}

export enum APPROVAL_STATES {
  IMPORTED = 'IMPORTED',
  EMPTY = 'EMPTY',
  DRAFT = 'DRAFT',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export const CLIENT_EDITABLE_STATES = [APPROVAL_STATES.DRAFT, APPROVAL_STATES.REJECTED, APPROVAL_STATES.IMPORTED]

export const ASSIGNMENT_STATUSES = {
  ASSIGNED: { label: 'Assigned', value: 'assigned' },
  UNASSIGNED: { label: 'Unassigned', value: 'unassigned' },
}

export const SPLIT_STATUSES = {
  ASSIGNED: { label: 'Split', value: 'split' },
  UNASSIGNED: { label: 'Unsplit', value: 'unsplit' },
}

export enum ASSET_TYPE {
  ART_TRACK = 'ArtTrack',
  ART_TRACK_VIDEO = 'ArtTrackVideo',
  MUSIC_VIDEO = 'MusicVideo',
  SOUND_RECORDING = 'SoundRecording',
  WEB = 'Web',
  RELEASE = 'Release',
  MOVIE = 'Movie',
  COMPOSITION = 'Composition',
  TV_EPISODE = 'TVEpisode',
  SONGMATE = 'Songmate',
  CHANNEL_VIDEO = 'ChannelVideo', // TODO: remove after complete migration of channel videos
}

export enum DELIVERY_PLATFORM {
  YOUTUBE = 'Youtube',
  AUDIO_SALAD = 'Audiosalad',
  DDEX = 'DDEX',
  SONGPITCH = 'Songpitch',
  SONGFINDER = 'Songfinder',
  SPOTIFY = 'Spotify',
  MICROLICENSING = 'Microlicensing',
}

export enum ANALYTICS_ASSET_TYPE {
  RELEASE = 'Release',
  TRACK = 'Track',
  CONTENT_ID = 'ContentIdAsset',
  CHANNEL_VIDEO = 'ChannelVideo',
}

export const PLATFORM_FOR_RELEASES = [DELIVERY_PLATFORM.AUDIO_SALAD, DELIVERY_PLATFORM.DDEX]

export type FileStruct = {
  file: File
  uuid?: string
  userUuid?: string
  upload: boolean
  uploadUrl?: string
  storagePath?: string
  downloadUrl?: string
}

export const DDEXFileType = {
  CSV: CSV_MIME_TYPE,
  AUDIO: AUDIO_WAV_MIME_TYPE,
  IMAGE: IMG_JPEG_MIME_TYPE,
}

export type DDEXUpload = {
  userUuid: string
  csv: File
  wavs: File[]
  jpegs: File[]
  uploadUrl?: string
}

export type TASFileStructContent = {
  uuid?: string
  file: File
  userUuid?: string
  uploadUrl?: string
  storagePath?: string
  downloadUrl?: string
  index?: number
}

export type TASFileStruct = {
  [uuid: string]: TASFileStructContent
}

export type AudioUploadCSVRow = {
  title: string
  artist: string
  album: string
  isrc: string
  upc: string
  label: string
  customId: string
  iswc: string
  isCover: string
  filename: string
}

export enum CLAIM_STATUSES {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  PENDING = 'pending',
  TAKEDOWN = 'takedown',
  TAKEN_DOWN = 'takendown',
  POTENTIAL = 'potential',
  APPEALED = 'appealed',
  DISPUTED = 'disputed',
}

export enum DISPUTE_REASON {
  AUTHORIZED = 'Authorized',
  FAIR_USE = 'FairUse',
  PUBLIC_DOMAIN = 'PublicDomain',
  ORIGINAL = 'Original',
}

export enum HISTORY_EVENT_TYPE {
  CLAIM_CREATE = 'ClaimCreate',
  DISPUTE_CREATE = 'DisputeCreate',
  CLAIM_CREATE_PENDING = 'ClaimCreatePending',
  CLAIM_REINSTATE = 'ClaimReinstate',
  CLAIM_UPDATE = 'ClaimUpdate',
  DISPUTE_APPEALED = 'DisputeAppealed',
  CLAIM_RELEASE = 'ClaimRelease',
  CLAIM_CLOSE = 'ClaimClose',
  APPEALED_CANCELLED = 'AppealedCancelled',
}

export enum CLAIM_TAKE_ACTION_ROLE {
  CLIENT = 'Client',
  ADMIN = 'Admin',
}

export enum AssetEarningCurveMonthlyType {
  RELEASE = 'Release',
  TRACK = 'Track',
}

export enum STORE_DELIVERY_STATE {
  LIVE = 'LIVE',
  PENDING = 'PENDING',
  ERROR = 'ERROR',
  TAKEDOWN = 'TAKEDOWN',
}
export enum LABEL_VALIDITY {
  NOT_CHECKED = 'NOT_CHECKED',
  EMPTY = 'EMPTY',
  VALID = 'VALID',
  INVALID = 'INVALID',
}

export enum UPLOAD_FILE_STATUS {
  UPLOADED = 'File Uploaded',
  MISSING = 'Missing File',
  UPLOADING = 'Loading...',
}

export enum RELEASE_FORMATS {
  ALBUM = 'Album',
  EP = 'EP',
  SINGLE = 'Single',
}

export enum PARTICIPANT_ROLES {
  ARRANGER = 'Arranger',
  CHOIR = 'Choir',
  COMPOSER = 'Composer',
  CONDUCTOR = 'Conductor',
  ENGINEER = 'Engineer',
  MASTERING_ENGINEER = 'MasteringEngineer',
  ENSEMBLE = 'Ensemble',
  FEATURED_ARTIST = 'FeaturedArtist',
  LYRICIST = 'Lyricist',
  MAIN_ARTIST = 'MainArtist',
  MIXER = 'Mixer',
  ORCHESTRA = 'Orchestra',
  OTHER = 'Other',
  PERFORMER = 'Performer',
  PRODUCER = 'Producer',
  PUBLISHER = 'Publisher',
  REMIXER = 'Remixer',
  SOLOIST = 'Soloist',
  SONG_WRITER = 'SongWriter',
  VOCALIST = 'Vocalist',
}

export const SPOTIFY_CLAIM = 'https://artists.spotify.com/claim'

export enum STORES {
  Youtube = 'Youtube',
  YoutubeMusic = 'YoutubeMusic',
  Spotify = 'Spotify',
  Apple = 'Apple',
  iTunes = 'iTunes',
  Audiomack = 'Audiomack',
  Deezer = 'Deezer',
  Amazon = 'Amazon',
  AmazonMusic = 'AmazonMusic',
  Tencent = 'Tencent',
  Mixcloud = 'Mixcloud',
  Napster = 'Napster',
  AudibleMagic = 'AudibleMagic',
  iHeartRadio = 'iHeartRadio',
  TikTok = 'TikTok',
  QOBUZ = 'QOBUZ',
  Anghami = 'Anghami',
  iMusica = 'iMusica',
  Slacker = 'Slacker',
  Pandora = 'Pandora',
  TouchTunes = 'TouchTunes',
  Curve = 'Curve', // check if has images
  NetEase = 'NetEase',
  Boomplay = 'Boomplay',
  Facebook = 'Facebook',
  FacebookVideo = 'FacebookVideo',
  AWA = 'AWA',
  SoundCloud = 'SoundCloud',
  SevenDigital = 'SevenDigital',
  JioSaavn = 'JioSaavn',
  Gracenote = 'Gracenote',
  KKBOX = 'KKBOX',
  Tidal = 'Tidal',
  TidalVideo = 'TidalVideo',
  APITest = 'APITest',
  Songpitch = 'Songpitch',
  Songfinder = 'Songfinder',
  AcrCloud = 'AcrCloud',
  Adaptr = 'Adaptr',
  Ami = 'Ami',
  Flo = 'Flo',
  // iMusicaAsDistro = 'iMusicaAsDistro',
  Jaxsta = 'Jaxsta',
  JOOX = 'JOOX',
  Leaplay = 'Leaplay',
  Lickd = 'Lickd',
  Peloton = 'Peloton',
  Trebel = 'Trebel',
  VevoVideo = 'VevoVideo',
  Alibaba = 'Alibaba',
  TunedGlobal = 'TunedGlobal',
  Microlicensing = 'Microlicensing',
}

export const STORES_IN_DISTRIBUTION_ANALYTICS = [
  STORES.Spotify,
  STORES.Amazon,
  STORES.Apple,
  STORES.Deezer,
  STORES.SoundCloud,
  STORES.Pandora,
]

export enum LICENSE_TYPE {
  CHANNEL_WHITELIST = 'ChannelWhitelist',
  MICROLICENSING = 'Microlicensing',
  GENERATED_CODE = 'GeneratedCode',
  THIRD_PARTY = 'ThirdParty',
}

export enum TOP_ASSETS_TYPE {
  EARNINGS = 'earnings',
  CLAIMS = 'claims',
  VIEWS = 'views',
}

export const FIRST_DATE_WITH_DISTRIBUTION_ANALYTICS_DATA = '2024-01-01'
export const DEFAULT_ANALYTICS_START_DATE = moment().subtract(33, 'days')
export const DEFAULT_ANALYTICS_END_DATE = moment().subtract(3, 'days')
