import { SERVICE_NAME } from '../../../../constants'
import { IGenericResponse } from '../../../api.types'
import clientWrapper from '../../../clientWrapper'

export type IPreviewClaimEmailProps = {
  claimUuid: string
  emailTemplateUuid: string
}

export type IPreviewClaimEmailResponse = IGenericResponse<
  'previewClaimEmail',
  {
    template: {
      uuid: string
      subject: string
      body: string
    }
    errors: string[]
  }
>

export async function previewClaimEmail(input: IPreviewClaimEmailProps): Promise<IPreviewClaimEmailResponse> {
  const query = `
    query previewClaimEmail($input: PreviewClaimEmailTemplateFilterInput!) {
      previewClaimEmail (
        input: $input
      ) {
          template {
            uuid
            subject
            body
          }
          errors
      }
    }
  `
  return (await clientWrapper(SERVICE_NAME.ASSETS, query, { input })) as IPreviewClaimEmailResponse
}
