import clientWrapper from '../../clientWrapper'
import { IGenericResponse } from '../../api.types'
import { assetFullFragment } from '../fragments'
import { IPaginationParams } from '../../../types/common'
import { IAssetBasic } from '../../../models/assets/AssetBasic.model'
import {
  SERVICE_NAME,
  DELIVERY_PLATFORM,
  ASSET_TYPE,
  APPROVAL_STATES,
  PLATFORM_STATES,
  STORES,
} from '../../../constants'

export type IGetAssetsResponse = IGenericResponse<
  'assets',
  {
    assets: IAssetBasic[]
    total: number
  }
>

export type IGetAssetsProps = {
  pagination?: IPaginationParams
  filters?: {
    userUuid?: string
    siteUuid?: string
    search?: string
    multipleIds?: string[]
    month?: string
    textFilter?: string
    isAssigned?: boolean
    isSplit?: boolean
    isCompositionShare?: boolean
    assetId?: string
    hasActiveReferenceId?: boolean
    assetTypes?: ASSET_TYPE[]
    platformName?: DELIVERY_PLATFORM
    platformStates?: PLATFORM_STATES[]
    assetState?: APPROVAL_STATES[]
    hasCustomId?: boolean
    releaseAfterDate?: string
    uuid?: string
    importSource?: string
    upc?: string
    stores?: STORES[]
    releaseUuid?: string
  }
}
export async function getAssets(
  { pagination, filters }: IGetAssetsProps,
  fragment: string = assetFullFragment
): Promise<IGetAssetsResponse> {
  const query = `
    query assets ($pagination: PaginationInput, $filters: AssetsFilterInput!) {
      assets (pagination: $pagination, filters: $filters) {
        total
        ${fragment}
        pagination {
           sort
           sortDirection
         }
      }
    }
  `
  return (await clientWrapper(SERVICE_NAME.ASSETS, query, { pagination, filters })) as IGetAssetsResponse
}
